import Vue from 'vue'
import Router from 'vue-router'



const DashContainer = () => import('../containers/DashContainer.vue');
const TheContainer = () => import('../containers/TheContainer.vue');
const Home = () => import('../views/Home.vue');
const Dashboard = () => import('../views/Dashboard.vue');
const Users = () => import('../views/pages/UsersPage.vue');


Vue.use(Router);

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  routes: [
    {
      path: '/',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home
        }
      ]
    },
    {
      path: '/dashboard',
      component: DashContainer,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'users',
          name: 'Users',
          component: Users
        },
      ]
    }
  ]
})

export default router
