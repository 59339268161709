import {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilFingerprint,
  cilPencil
} from '@coreui/icons'

import { logo } from './logo'

export const iconsSet: { [key: string]: string[] } = Object.assign({}, {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilFingerprint,
  cilPencil,
  logo
})
